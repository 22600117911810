<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="350"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to=""
        class="d-flex align-center text-decoration-none"
       
      >
        <v-img
          :src="require('@/assets/images/logos/eyebank-logo.png')"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
          @click="neww()"
        ></v-img>
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">
            EBSR
          </h2>
        </v-slide-x-transition> -->
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >

    <nav-menu-link
        title="Dashboard"
        :to="{name: 'dashboard'}"
        :icon="icons.mdiHomeOutline"
        v-if="this.localData.Role_id === 132"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'dashboardAssistentManager'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 6"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'AccountsDashboard'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 7"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'DashboardLabTechnician'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 2 || this.localData.Role_id === 134"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'dashboard'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 8"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'DashboardCounsellor'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 1"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'DashboardMedicalDirector'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 3"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'DashboardHCRP'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 9"
      ></nav-menu-link>
      <nav-menu-link
        title="Dashboard"
        :to="{name: 'DashboardTissueEvaluator'}"
        :icon="icons.mdiHomeOutline"
        v-else-if="this.localData.Role_id === 124"
      ></nav-menu-link>
      
 <!-- v-if="localData.userDesignation == 'Admin'" -->
      <nav-menu-group
        title="Masters"
        :icon="icons.mdiFileOutline"
        v-if="this.localData.Role_id === 132 || this.localData.Role_id === 8"
      >
        <nav-menu-link
          title="User Roles"
          :to="{ name: 'userRoles' }"
          
        ></nav-menu-link>
        <nav-menu-link
          title="System Users"
          :to="{ name: 'systemUsers' }"
        ></nav-menu-link>
        <!-- <nav-menu-link
          title="Permissions"
          :to="{ name: 'error-404' }"
        ></nav-menu-link> -->
        <nav-menu-link
          title="Counsellor Cum Technician"
          :to="{ name: 'counselor' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Lab Technicians"
          :to="{ name: 'technicians' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Doctors"
          :to="{ name: 'doctors' }"
        ></nav-menu-link>
      
        <nav-menu-link
          title="Centers/Branches"
          :to="{ name: 'branches' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Hospitals/Eye Donation Centers"
          :to="{ name: 'eyeDonationCenter' }"
        ></nav-menu-link>
         <nav-menu-link
          title="Medical Director"
          :to="{ name: 'MedicalDirector' }" 
        ></nav-menu-link>
         <nav-menu-link
          title="Asst Manager Distribution"
          :to="{ name: 'AsstManager' }"
        ></nav-menu-link>
         <nav-menu-link
          title="Manager"
          :to="{ name: 'Manager' }"
        ></nav-menu-link>
         <nav-menu-link
          title="Assistant HCRP Manager"
          :to="{ name: 'HcrpManager' }"
        ></nav-menu-link>
        
        <!-- <nav-menu-link
          title="Retrieval form"
          :to="{ name: 'retrieval_form' }"
        ></nav-menu-link> -->
        <!-- <nav-menu-link
          title="Laboratory"
          :to="{ name: 'laboratory' }"
        ></nav-menu-link> -->
    
      </nav-menu-group>
      

      

      <nav-menu-group
        title="Donor's Area"
        :icon="icons.mdiFileOutline"
      >
       <nav-menu-link
          title="Donor Notification"
          :to="{ name: 'DonorNotification' }"
        ></nav-menu-link>

        <nav-menu-link
          title="Donors Information"
          :to="{ name: 'donors' }"
        ></nav-menu-link>

        <nav-menu-link
          title="Re-Assign Cases"
          :to="{ name: 'ReAssignCases' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 8 || this.localData.Role_id === 134"
        ></nav-menu-link>
        
        <!-- <nav-menu-link
          title="Potential Eye Donor Referral Information"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Donor Info"
          :to="{ name: 'error-404' }"
        ></nav-menu-link> -->
      </nav-menu-group>


      <nav-menu-group
        title="Distribution"
        :icon="icons.mdiFileOutline"
        v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9 || this.localData.Role_id === 7"
      >
        <nav-menu-link
          title="Tissue Detail"
          :to="{ name: 'TissueDetails' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Tissue Offered"
          :to="{ name: 'TissueOfferedMain' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Bill of Lading"
          :to="{ name: 'BillOfLadingView' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 7 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Payment Receivable"
          :to="{ name: 'PaymentReceivable' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 8 || this.localData.Role_id === 7 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Adverse Reaction Report"
          :to="{ name: 'AdverseReactionReport' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Recipient information"
          :to="{ name: 'RecipientView' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 3 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Tissue Disposition"
          :to="{ name: 'TissueDispositions' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
        <nav-menu-link
          title="Tissue List"
          :to="{ name: 'TissueList' }"
          v-if="this.localData.Role_id === 132 || this.localData.Role_id === 8 || this.localData.Role_id === 6 || this.localData.Role_id === 134 || this.localData.Role_id === 2 || this.localData.Role_id === 9"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="Reports"
        :icon="icons.mdiFileOutline"
        v-if="this.localData.Role_id === 132 || this.localData.Role_id === 7 || this.localData.Role_id === 6  || this.localData.Role_id === 9  || this.localData.Role_id === 8 || this.localData.Role_id === 134"
      >
      <!-- <nav-menu-link
          title="Center Wise Reports"
          :to="{ name: 'CenterWiseReport' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Performance Report"
          :to="{ name: 'PerformanceReport' }"
        ></nav-menu-link>
       <nav-menu-link
          title="Financial Reports"
          :to="{ name: 'FinancialReports' }"
        ></nav-menu-link> -->

        <nav-menu-link
          title="Master Report"
          :to="{ name: 'MasterReport' }"

        ></nav-menu-link>


       <nav-menu-link
          title="All Donors Report"
          :to="{ name: 'AllDonorReport' }"

        ></nav-menu-link>
        
       <nav-menu-link
          title="Month Wise Reports"
          :to="{ name: 'MonthwiseReport' }"
          
        ></nav-menu-link>
       <nav-menu-link
          title="Chapter Wise Performance"
          :to="{ name: 'ChapterWisePerformance' }"
          
        ></nav-menu-link>
       <nav-menu-link
          title="EDCT Performance Report"
          :to="{ name: 'EDCPerformance' }"
          
        ></nav-menu-link>
        <nav-menu-link
          title="EBAI Revised Statistics"
          :to="{ name: 'EBAIRevised' }"
          
        ></nav-menu-link>

       <!-- <nav-menu-link
          title="Collection & Transplant Report"
          :to="{ name: 'CollectionTransplantReport' }"
          
        ></nav-menu-link> -->

       <nav-menu-link
          title="Surgeon Wise Report"
          :to="{ name: 'SurgeonWise' }"
          
        ></nav-menu-link>
       <nav-menu-link
          title="Unutilised Tissue Report"
          :to="{ name: 'UnutilisedTissue' }"
          
        ></nav-menu-link>
        <nav-menu-link
          title="Reported Deaths"
          :to="{ name: 'NotificationReportDeath' }"
          
        ></nav-menu-link>
        <nav-menu-link
          title="Reported Notifications"
          :to="{ name: 'NotificationReport' }"
          
        ></nav-menu-link>
        <nav-menu-link
          title="Hospital Wise Report"
          :to="{ name: 'HospitalWiseReport' }"
          
        ></nav-menu-link>
       <!-- <nav-menu-link
          title="Recipient Information"
          :to="{ name: 'Recipient' }"
        ></nav-menu-link> -->
        <!-- <nav-menu-link
          title="Adverse Reaction Report"
          :to="{ name: 'AdverseReaction' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Tissue Detail Form"
          :to="{ name: 'tissueDetail' }"
        ></nav-menu-link> -->
      </nav-menu-group>

      <!-- <nav-menu-group
        title="Lab"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Lab Findings & Observations"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Evaluation Process 1 & 2"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Slit Lamp Cornea Evaluation"
          :to="{ name: 'SlitLamp' }" 
        ></nav-menu-link>
      </nav-menu-group> -->

      <!-- <nav-menu-group
        title="Billing"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Raise Invoice"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Reconciliation"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Pending Payments"
          :to="{ name: 'error-404' }"
        ></nav-menu-link>
      </nav-menu-group> -->
      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <!-- <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>

// const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
// console.log("hellooo",localData.userDesignation)
// const valueaaa = localData.userDesignation
// console.log("hello",valueaaa)
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiEarthOff,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'



export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
   
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
  data(){
    return{
      localData:(JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token')),
      path:'',
    }
  },
//  this.localData.Role_id === 1 || this.localData.Role_id === 3 || this.localData.Role_id === 9|| this.localData.Role_id === 124"
  methods:{
    neww(){
      console.log("hello")
      if(this.localData.Role_id === 132){
       this.$router.push({ name: 'dashboard' })
      }else if(this.localData.Role_id === 6){
        this.$router.push({name: 'dashboardAssistentManager'})
      }else if(this.localData.Role_id === 7){
        this.$router.push({name: 'AccountsDashboard'})
      }else if(this.localData.Role_id === 2 || this.localData.Role_id === 134){
        this.$router.push({name:'DashboardLabTechnician'})
      }else if(this.localData.Role_id === 8){
        this.$router.push({name:'dashboard'})
      }else if(this.localData.Role_id === 1){
        this.$router.push({name: 'DashboardCounsellor'})
      }else if(this.localData.Role_id === 3){
        this.$router.push({name:'DashboardMedicalDirector'})
      }else if(this.localData.Role_id === 9){
        this.$router.push({name:'DashboardHCRP'})
      }else if(this.localData.Role_id === 124){
        this.$router.push({name: 'DashboardTissueEvaluator'})
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
