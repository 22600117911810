import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import DatetimePicker from 'vuetify-datetime-picker'
import axios from 'axios'
import VueImg from 'v-img';
import VueApexCharts from 'vue-apexcharts'
import VDatetimeField from 'v-datetime-field'
import VueCookies from 'vue-cookies';


Vue.use(VueCookies);

Vue.use(VDatetimeField)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.use(DatetimePicker,axios)

Vue.use(VueImg);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
