import Vue from 'vue'
import VueRouter from 'vue-router'
import { jwtDecode } from 'jwt-decode';

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/pages/login',
  },
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/AssistentManager',
    name: 'dashboardAssistentManager',
    component: () => import('@/views/AssistantManagerDashboard/Dashboard.vue')
  },
  {
    path: '/Accounts',
    name: 'AccountsDashboard',
    component: () => import('@/views/AccountsDashboard/Dashboard.vue')
  },
  {
    path: '/LabTechnician',
    name: 'DashboardLabTechnician',
    component: () => import('@/views/DashboardLabTechnician/Dashboard.vue')
  },
  {
    path: '/Counsellor-Cum-Technician',
    name: 'DashboardCounsellor',
    component: () => import('@/views/DashboardCounsellor/Dashboard.vue')
  },
  {
    path: '/Medical-Director',
    name: 'DashboardMedicalDirector',
    component: () => import('@/views/DashboardMedicalDirector/Dashboard.vue')
  },
  {
    path: '/Assistant-HCRP-Manager',
    name: 'DashboardHCRP',
    component: () => import('@/views/DashboardHCRP/Dashboard.vue')
  },
  {
    path: '/Tissue-Evaluator',
    name: 'DashboardTissueEvaluator',
    component: () => import('@/views/DashboardTissueEvaluator/Dashboard.vue')
  },
  {
    path: '/master/user-roles',
    name: 'userRoles',
    component: () => import('@/views/Masters/Roles/UserRoles.vue'),
  },
  {
    path: '/master/system-users',
    name: 'systemUsers',
    component: () => import('@/views/Masters/SystemUsers/SystemUsers.vue'),
  },
  {
    path: '/master/counselor',
    name: 'counselor',
    component: () => import('@/views/Masters/Counsellor/Counsellor.vue'),
  },
  {
    path: '/master/technicians',
    name: 'technicians',
    component: () => import('@/views/Masters/LabTechnicians/Technicians.vue'),
  },
  {
    path: '/master/doctors',
    name: 'doctors',
    component: () => import('@/views/Masters/Doctors/Doctors.vue'),
  },
  {
    path: '/master/branches',
    name: 'branches',
    component: () => import('@/views/Masters/Branches/Branches.vue'),
  },
  {
    path: '/master/eye-donation-center',
    name: 'eyeDonationCenter',
    component: () => import('@/views/Masters/Hospitals/EyeDonationCenter.vue'),
  },
  {
    path: '/master/Medical-Director',
    name: 'MedicalDirector',
    component: () => import('@/views/Masters/MedicalDirector/MedicalDirector.vue'),
  },
  {
    path: '/master/Asst-Manager-Distribution',
    name: 'AsstManager',
    component: () => import('@/views/Masters/AsstManager/AsstManager.vue'),
  },
  {
    path: '/master/Manager',
    name: 'Manager',
    component: () => import('@/views/Masters/Manager/Manager.vue'),
  },
  {
    path: '/master/HcrpManager',
    name: 'HcrpManager',
    component: () => import('@/views/Masters/HCRPManager/HcrpManager.vue'),
  },
  {
    path: '/Donors/Donor-Information',
    name: 'donors',
    component: () => import('@/views/Donor/DonorInformation/Donors.vue'),
  },
  {
    path: '/Donors/ReAssign-Donor-Information',
    name: 'ReAssignCases',
    component: () => import('@/views/Donor/ReAssignDonorInformation/Donors.vue'),
  },
  {
    path: '/Donors/Donor-Information/:donor_id',
    name: 'donorss',
    component: () => import('@/views/Donor/DonorInformation/Donors.vue'),
  },
  {
    path: '/Donor/notifications',
    name: 'Notifications',
    component: () => import('@/views/Donor/Notifications/Notifications.vue')
  },
  {
    path: '/Donor/donor-notification',
    name: 'DonorNotification',
    component: () => import('@/views/Donor/DonorNotification/DonorNotification.vue')
  },

  {
    path: '/Donor/form-a/:donor_id',
    name: 'Form_A',
    component: () => import('@/views/Donor/FormA/Form_A.vue')
  },
  {
    path: '/Donor/form-b/:donor_id',
    name: 'Form_B',
    component: () => import('@/views/Donor/FormB/Form_B.vue')
  },
  {
    path: '/Donor/form-c/:donor_id',
    name: 'Form_C',
    component: () => import('@/views/Donor/FormC/Form_C.vue')
  },
  {
    path: '/Donor/form-c-part/:donor_id',
    name: 'Form_Cpart',
    component: () => import('@/views/Donor/FormC/Form_Cpart.vue')
  },
  {
    path: '/Donor/retrieval-form/:donor_id',
    name: 'Retrieval_Form',
    component: () => import('@/views/Donor/Retrieval/RetrievalForm.vue')
  },
  {
    path: '/Donor/Hemodilution/:donor_id',
    name: 'Hemodilution',
    component: () => import('@/views/Donor/Hemodilution/Hemodilution.vue')
  },
  {
    path: '/Donor/slitlamp/:donor_id',
    name: 'SlitLamp',
    component: () => import('@/views/Donor/SlitLamp/SlitLampMain.vue'),
  },
  {
    path: '/Donor/Recipient/:donor_id',
    name: 'Recipient',
    component: () => import('@/views/Donor/Recipient/RecipientMain.vue'),
  },
  {
    path: '/Donor/TissueDetail/:donor_id',
    name: 'TissueDetail',
    component: () => import('@/views/Donor/TissueDetails/TissueDetail.vue'),
  },
  {
    path: '/Donor/AdverseReaction/:donor_id',
    name: 'AdverseReaction',
    component: () => import('@/views/Donor/AdverseReaction/AdverserReactionMain.vue'),
  },

  {
    path: '/Donor/AdverseReactionForm/:donor_id',
    name: 'AdverseReactionForm',
    component: () => import('@/views/pages/AdverseReactionForm/AdverserReactionMain.vue'),
  },
  {
    path: '/Donor/Bill-of-lading/:donor_id/',
    name: 'BillOfLading',
    component: () => import('@/views/Donor/BillOfLading/BillOfLading.vue'),
  },

  // {
  //   path: '/Donor/Bill-of-lading/:donor_id/:tissue_id',
  //   name: 'BillOfLading',
  //   component: () => import('@/views/Donor/BillOfLading/BillOfLading.vue'),
  // },
  {
    path: '/Donor/Tissue-Disposition/:donor_id',
    name: 'TissueDisposition',
    component: () => import('@/views/Donor/TissueDisposition/TissueDisposition.vue'),
  },


  // Distribution
  {
    path: '/Distribution/Tissue-Detail/',
    name: 'TissueDetails',
    component: () => import('@/views/Distribution/TissueDetail/TissueDetail.vue'),
  },
  {
    path: '/Distribution/Tissue-Offered/',
    name: 'TissueOfferedMain',
    component: () => import('@/views/Distribution/TissueOffered/TissueOffered.vue'),
  },
  {
    path: '/Distribution/Bill-of-lading/',
    name: 'BillOfLadingView',
    component: () => import('@/views/Distribution/BillOfLadingView/BillOfLadingView.vue'),
  },
  {
    path: '/Distribution/Payment-Receivable/',
    name: 'PaymentReceivable',
    component: () => import('@/views/Distribution/PaymentReceivable/PaymentReceivable.vue'),
  },
  {
    path: '/Distribution/Bill-of-lading/:tissue_id',
    name: 'BillOfLadingViewitem',
    component: () => import('@/views/Distribution/BillOfLadingView/BillOfLadingView.vue'),
  },
  {
    path: '/Distribution/Adverse-Reaction-Report/',
    name: 'AdverseReactionReport',
    component: () => import('@/views/Distribution/AdverseReactionReport/AdverseReactionReport.vue'),
  },
  {
    path: '/Distribution/Recipient-information/',
    name: 'RecipientView',
    component: () => import('@/views/Distribution/Recipient/RecipientView.vue'),
  },
  {
    path: '/Donor/RecipientForm/:donor_id',
    name: 'RecipientViewForm',
    component: () => import('@/views/pages/RecipientForm/RecipientMain.vue'),
  },
  {
    path: '/Distribution/Tissue-Disposition/',
    name: 'TissueDispositions',
    component: () => import('@/views/Distribution/TissueDisposition/TissueDisposition.vue'),
  },
  {
    path: '/Distribution/Tissue-List/',
    name: 'TissueList',
    component: () => import('@/views/Distribution/TissueList/TissueList.vue'),
  },


  // Report
  // {
  //   path: '/Reports/Center-Wise-Report/',
  //   name: 'CenterWiseReport',
  //   component: () => import('@/views/Reports/CenterWiseReport.vue'),
  // },

  // {
  //   path: '/Reports/Performance-Report/',
  //   name: 'PerformanceReport',
  //   component: () => import('@/views/Reports/PerformanceReport.vue'),
  // },

  {
    path: '/Reports/Financial-Reports/',
    name: 'FinancialReports',
    component: () => import('@/views/Reports/FinancialReports.vue'),
  },
  {
    path: '/Reports/Master-Report/',
    name: 'MasterReport',
    component: () => import('@/views/Reports/MasterReport.vue'),
  },
  {
    path: '/Reports/All-Donor-Report/',
    name: 'AllDonorReport',
    component: () => import('@/views/Reports/AllDonorReport.vue'),
  },
  {
    path: '/Reports/Monthwise-Reports/',
    name: 'MonthwiseReport',
    component: () => import('@/views/Reports/MonthwiseReport.vue'),
  },
  {
    path: '/Reports/Chapter-Wise-Performance/',
    name: 'ChapterWisePerformance',
    component: () => import('@/views/Reports/ChapterWisePerformance.vue'),
  },
  {
    path: '/Reports/EDC-Performance-Report/',
    name: 'EDCPerformance',
    component: () => import('@/views/Reports/EDCPerformance.vue'),
  },
  {
    path: '/Reports/EBAI-Revised-Ststistics-Quarterly/',
    name: 'EBAIRevised',
    component: () => import('@/views/Reports/EBAIRevisedStstisticsQuarterly.vue'),
  },
  {
    path: '/Reports/Collection-and-Transplant-Report/',
    name: 'CollectionTransplantReport',
    component: () => import('@/views/Reports/CollectionTransplantReport.vue'),
  },
  {
    path: '/Reports/Surgeon-Wise-Report/',
    name: 'SurgeonWise',
    component: () => import('@/views/Reports/SurgeonWise.vue'),
  },
  {
    path: '/Reports/Unutilised-Tissue-Report/',
    name: 'UnutilisedTissue',
    component: () => import('@/views/Reports/UnutilisedTissue.vue'),
  },
  {
    path: '/Reports/NotificationReportDeath/',
    name: 'NotificationReportDeath',
    component: () => import('@/views/Reports/NotificationReportDeath.vue'),
  },

  {
    path: '/Reports/NotificationReport/',
    name: 'NotificationReport',
    component: () => import('@/views/Reports/NotificationReport.vue'),
  },
  {
    path: '/Reports/HospitalWiseReport/',
    name: 'HospitalWiseReport',
    component: () => import('@/views/Reports/HospitalWiseReport.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/forgot',
    name: 'pages-forgot',
    component: () => import('@/views/pages/Forgot.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/expire-page',
    name: 'expire-page',
    component: () => import('@/views/Expire.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


// Middleware to check JWT token expiration
router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('token');

  console.log("tokens", token, to, from)
  if (!token) {
    if (from.name === 'pages-login') {
      location.href = "/pages/login"
      next();
    }
    next();
  } else {
    // Check token expiration
    const tokenExpiration = jwtDecode(token).exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now();

    if (currentTime > tokenExpiration) {
      // Token has expired, redirect to home page
      localStorage.removeItem("token")
      next();
    } else {
      next();
    }
  }
  next();
});


export default router
